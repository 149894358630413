body {
/*     background: #fafafa; */
/*     font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
/*     color: #333; */
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    right: -5px;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(45deg);
    ms-transform: rotate(45deg);
    o-transform: rotate(45deg);
    overflow: hidden;
    position: absolute;
    top: 5px;
    transform: rotate(45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(45deg);
    white-space: nowrap;
    width: 100px;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 5px 40px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 100%;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}
.cnpTable{
    width: 100%;
    text-align: center
}

/* jhipster-needle-css-add-main JHipster will add new css style */

@font-face {
    font-family: 'flowinn';
    src:  url('../fonts/flowinn.eot?zesa0q');
    src:  url('../fonts/flowinn.eot?zesa0q#iefix') format('embedded-opentype'),
    url('../fonts/flowinn.ttf?zesa0q') format('truetype'),
    url('../fonts/flowinn.woff?zesa0q') format('woff'),
    url('../fonts/flowinn.svg?zesa0q#flowinn') format('svg');
    font-weight: normal;
    font-style: normal;
}

.fl {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'flowinn' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    margin-left: 3px;
    margin-right: 2px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fl-infarmed:before {
    content: "\e900";
}

@charset "UTF-8";
/* [START] The side navigation menu  ***************************************/
.sidenav {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 24px;
	padding-right: 0px;
}

.sidenav-toggle {
	display: block;
	margin-left: 15px;
	float: left;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 99;
}

.sidenav-overlay {
	height: 100%;
	width: 0px;
	position: fixed;
	top: 0;
	z-index: 998;
}
.sidenav .nav-pills > li > a {
	border-radius: 0px;
}
.sidenav .closebtn {
	line-height: 1;
	position: absolute;
	top: -8px;
	right: 0px;
	font-size: 24px;
}

.sidenav .closebtn:hover {
	text-decoration: none;
}

.spacer {
	padding-left: 20px;
}
/* [END] The side navigation menu */
@charset "UTF-8";

/** The Magic **/
btn-breadcrumb>.btn.disabled {
    opacity: 1 !important;
    color: #999;
}

.btn-breadcrumb .btn:not(:last-child):after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: 100%;
    z-index: 3;
}
.btn-breadcrumb .btn:not(:last-child):before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 10px solid rgb(173, 173, 173);
    position: absolute;
    top: 50%;
    margin-top: -17px;
    margin-left: 1px;
    left: 100%;
    z-index: 3;
}

/** The Spacing **/
.btn-breadcrumb .btn {
    padding:6px 12px 6px 24px;
}
.btn-breadcrumb .btn:first-child {
    padding:6px 6px 6px 10px;
}
.btn-breadcrumb .btn:last-child {
    padding:6px 18px 6px 24px;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
    border-left: 10px solid #fff;
}
.btn-breadcrumb .btn.btn-default:not(:last-child):before {
    border-left: 10px solid #ccc;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
    border-left: 10px solid #ebebeb;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
    border-left: 10px solid #adadad;
}

/** Primary button **/
.btn-breadcrumb .btn.btn-primary:not(:last-child):after {
    border-left: 10px solid #428bca;
}
.btn-breadcrumb .btn.btn-primary:not(:last-child):before {
    border-left: 10px solid #357ebd;
}
.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):after {
    border-left: 10px solid #3276b1;
}
.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):before {
    border-left: 10px solid #285e8e;
}

/** Success button **/
.btn-breadcrumb .btn.btn-success:not(:last-child):after {
    border-left: 10px solid #5cb85c;
}
.btn-breadcrumb .btn.btn-success:not(:last-child):before {
    border-left: 10px solid #4cae4c;
}
.btn-breadcrumb .btn.btn-success:hover:not(:last-child):after {
    border-left: 10px solid #47a447;
}
.btn-breadcrumb .btn.btn-success:hover:not(:last-child):before {
    border-left: 10px solid #398439;
}

/** Danger button **/
.btn-breadcrumb .btn.btn-danger:not(:last-child):after {
    border-left: 10px solid #d9534f;
}
.btn-breadcrumb .btn.btn-danger:not(:last-child):before {
    border-left: 10px solid #d43f3a;
}
.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):after {
    border-left: 10px solid #d2322d;
}
.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):before {
    border-left: 10px solid #ac2925;
}

/** Warning button **/
.btn-breadcrumb .btn.btn-warning:not(:last-child):after {
    border-left: 10px solid #f0ad4e;
}
.btn-breadcrumb .btn.btn-warning:not(:last-child):before {
    border-left: 10px solid #eea236;
}
.btn-breadcrumb .btn.btn-warning:hover:not(:last-child):after {
    border-left: 10px solid #ed9c28;
}
.btn-breadcrumb .btn.btn-warning:hover:not(:last-child):before {
    border-left: 10px solid #d58512;
}

/** Info button **/
.btn-breadcrumb .btn.btn-info:not(:last-child):after {
    border-left: 10px solid #5bc0de;
}
.btn-breadcrumb .btn.btn-info:not(:last-child):before {
    border-left: 10px solid #46b8da;
}
.btn-breadcrumb .btn.btn-info:hover:not(:last-child):after {
    border-left: 10px solid #39b3d7;
}
.btn-breadcrumb .btn.btn-info:hover:not(:last-child):before {
    border-left: 10px solid #269abc;
}

html, body {
	height: 100%;
}

body {
	overflow-x: hidden;
}

#main {
	min-height: 100%;
	padding-bottom: 50px;
}

#footer {
	margin-top: -50px; /* negative value of footer height */
}

blockquote {
	font-size: 14px;
}

.file {
	visibility: hidden;
	position: absolute;
}

.input-sm {
	min-width: 125px;
}

.modal-exl {
	width: 90%;
}

.ui-notification {
	min-width: 300px !important;
	max-width: 1200px !important;
	width: auto !important;
}

.floatThead-table, .tableCell {
	display: table !important;
	table-layout: auto !important;
}

.floatThead-container {
	background-color: white;
}

div.tooltip-inner {
	max-width: 500px;
}

.nopadding {
	padding: 0 !important;
	margin: 0 !important;
}

.form-control-feedback {
	z-index: -1;
	right: 8px;
	top: 50%;
	margin-top: -15px;
}

.has-error .form-control-feedback {
	z-index: 5;
}

label {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.pagination {
	margin: 0;
}

.navbar {
	margin-bottom: 10px;
}

.input-xs {
	height: 22px;
	padding: 2px 5px;
	line-height: 1.5;
	max-width: 80px;
	min-width: 50px;
}

.margin-top-20 {
	margin-top: 20%;
}

body>.ui-select-bootstrap.open {
	z-index: 1500;
}

.ui-select-search.input-xs {
	max-width: 99%;
}

#loginform {
	padding: 15px 0;
}

.ui-select-sm {
	margin-top: -2px;
}

.ui-select-bootstrap>.ui-select-choices {
	min-width: 100% ! important;
	width: auto ! important;
	/* 	width: 100%; */
}

.ui-select-container .ui-select-refreshing {
	font-family: 'FontAwesome';
}

.form-horizontal .form-group-sm {
	margin-right: -15px;
	margin-left: -15px;
}

.form-group>.form-group {
	margin-bottom: 0;
}

.form-inline label {
	line-height: 2em;
}

.form-inline .ui-select-container {
	display: inline-block;
	padding: 0;
	vertical-align: middle;
	min-width: 350px !important;
}

.form-inline .ui-select-container.noLabelSelect {
	width: 100% !important;
}

.form-inline .ui-select-placeholder {
	display: inline-block;
	height: 18px !important;
	padding: 6px 0;
	text-indent: 12px;
	min-width: 350px !important;
	width: 100% !important;
}

.form-inline .ui-select-match-text {
	padding: 6px 0 0 12px;
	overflow: hidden;
	width: 93%;
}

.ui-select-match-text {
	padding-right: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.form-inline .ui-select-container input.ui-select-search {
	display: inline-block;
	width: 100% !important;
}

.form-inline .ui-select-container .ui-select-toggle {
	padding: 0;
	margin-top: -1px;
	min-width: 350px !important;
	width: 100% !important;
}

.dropdown-menu>li>.checkbox {
	padding: 3px 20px;
	margin: 0;
}

.dropdown-menu>li>.checkbox:hover, .dropdown-menu>li>.checkbox:focus {
	text-decoration: none;
	color: #262626;
	background-color: #f5f5f5;
}

.panel-heading-filter .panel-title {
	line-height: 2em;
}

.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
	vertical-align: middle
}

.table thead * {
	text-align: center !important;
}

.table tbody, .table tbody * {
	text-align: center !important;
}

.table tbody .table-left, .table tbody .table-left * {
	text-align: left !important;
}
.table tbody .table-center, .table tbody .table-center * {
	text-align: center !important;
}

.table tbody .table-right, .table tbody .table-right * {
	text-align: right !important;
}

.table tbody .table-left input, .table tbody .table-right input, .table tbody input {
	display: table-cell;
}

.table tbody tr:hover, .hover {
	background-color: #dff0d8 !important;
}

.textAlignPicking
{
	text-align: center;
}

/* FIX FOR UI-CALENDAR CONTAINER ---------------------------------*/
.fc .fc-event-container {
	padding: 2px;
}

.fc .fc-event-container .fc-event {
	padding: 4px;
}

/* FIX FOR UIB-TABS CONTAINER ---------------------------------*/
.tab-content {
	margin-top: -1px;
}

.multiDemo .nav-tabs, .multiDemo .nav-pills {
	float: left;
	width: 30%;
}

.multiDemo .tab-content {
	float: left;
	width: 70%;
}

/* 	Pills and Tabs ============================================ */
.tab-content {
	min-height: 220px;
	margin-top: 0;
}

.tab-content .tab-pane>div {
	min-height: 200px;
}

.tabs-left>.nav-tabs {
	border-bottom: 0;
}

.tab-content>.tab-pane, .pill-content>.pill-pane {
	display: none;
}

.tab-content>.active, .pill-content>.active {
	display: block;
}

.tabs-left>.nav-tabs>li {
	float: none;
}

.tabs-left>.nav-tabs>li>a {
	min-width: 74px;
	margin-right: 0;
	margin-bottom: 3px;
}

.tabs-left>.nav-tabs {
	float: left;
	margin-right: -1px;
	margin-top: 10px;
	border-right: 1px solid #ddd;
}

.tabs-left>.nav-tabs>li>a {
	margin-right: -1px;
	-webkit-border-radius: 4px 0 0 4px;
	-moz-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.tabs-left>.nav-tabs>li>a:hover, .tabs-left>.nav-tabs>li>a:focus {
	border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left>.nav-tabs .active>a, .tabs-left>.nav-tabs .active>a:hover, .tabs-left>.nav-tabs .active>a:focus {
	border-color: #ddd transparent #ddd #ddd;
	*border-right-color: #ffffff;
}

.tabs-left .tab-content .tab-pane .panel {
	border: 1px solid #ddd;
}
/* FIX FOR LABELS ---------------------------------*/
label.label-required:after {
	content: " *";
	color: red;
}

/* [START] UIB-DATEPICKER ---------------------------------*/
.uib-datepicker {
	padding: 0 5px;
}

.uib-button-bar {
	padding: 5px;
}

.uib-datepicker .btn {
	border-radius: 0;
}
/* [END] UIB-DATEPICKER ---------------------------------*/

/* [START] FOOTER --------------------------------- */
.footer_wrapper {
	position: relative;
	margin-bottom: 0;
	border: 0;
}

.footer_wrapper span {
	display: block;
	text-align: right;
}

.footer_wrapper span a {
	background: url("../images/logo_flowinn.png") no-repeat 10px 0;
	height: 40px;
	width: 125px;
	display: inline-block;
	-webkit-transition: 0.5s ease;
	-moz-transition: 0.5s ease;
	-o-transition: 0.5s ease;
	transition: 0.5s ease;
}

.footer_wrapper span a:hover {
	background-position: 10px -40px;
	-webkit-transition: 0.5s ease;
	-moz-transition: 0.5s ease;
	-o-transition: 0.5s ease;
	transition: 0.5s ease;
}

.social_links {
	padding: 0;
	margin: 0;
	display: block;
	overflow: hidden;
	list-style: none;
}

.social_links li {
	float: left;
	margin-right: 4px;
}

.social_links li a {
	display: block;
	width: 50px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	font-size: 25px;
	color: #fff;
}

.social_links li a:hover, .social_links li a:focus {
	text-decoration: none;
}

.social_links li a.twitter:hover {
	color: #55acee;
}

.social_links li a.facebook:hover {
	color: #3b5998;
}

.social_links li a.gplus:hover {
	color: #dd4b39;
}
/* [END] FOOTER ---------------------------------*/
/* [START] APPLICATIONS MODULE ---------------------------------*/
.app_box {
	min-height: 200px;
}

.app_link span {
	display: block;
}

.app_link img {
	transition: all .2s ease-in-out;
	transform: scale(0.9);
	max-width: 200px;
}

.app_link:hover img {
	transform: scale(1);
}
/* [END] APPLICATIONS MODULE ---------------------------------*/
/* [START] REPORTS MODULE ---------------------------------*/
.report_box {
	min-height: 190px;
	padding: 0;
	margin: 0 15px;
	overflow: hidden;
	border: 5px solid #fff;
	-webkit-box-shadow: 1px 1px 1px 1px #ccc;
	-moz-box-shadow: 1px 1px 1px 1px #ccc;
	box-shadow: 1px 1px 1px 1px #ccc;
}

.img_box img {
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
}

.img_box .full-caption {
	background-color: rgba(0, 0, 0, 0.8);
	position: absolute;
	color: white;
	z-index: 100;
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
	left: 0;
	width: 100%;
	height: 100%;
	top: -101%;
	padding: 15px;
}

.img_box:hover .img_caption {
	visibility: hidden;
}

.img_box:hover .full-caption {
	-moz-transform: translateY(100%);
	-o-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

.img_box .full-caption h4 {
	border-bottom: 1px solid white;
	padding-bottom: 10px;
}

.img_box .export_types {
	margin-top: 34px;
}

.img_box .export_types a {
	font-size: 28px;
	padding: 10px;
	color: white;
	text-decoration: none;
}

.img_box .export_types a.html:hover {
	color: #0B51C0;
}

.img_box .export_types a.pdf:hover {
	color: #BB0706;
}

.img_box .export_types a.excel:hover {
	color: #1E7145;
}

/* [END] REPORTS MODULE ---------------------------------*/
[draggable=false] .dragHandle {
	cursor: not-allowed;
	top: 14px;
}

[draggable=true] .dragHandle {
	cursor: move;
	top: 14px;
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop into it once it's empty
 */
.multiDemo ul[dnd-list] {
	min-height: 190px;
	padding: 1.1em 0;
	margin: 0;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
.multiDemo ul[dnd-list] .dndPlaceholder {
	background-color: #ddd;
	display: block;
	min-height: 45px;
}

.multiDemo ul[dnd-list] li {
	display: block;
	margin-bottom: -1px;
}

/*Reports Drag and Drop*/

.reportDnD .dndDragging{
    opacity: 0.7;
}

.reportDnD .dndDraggingSource {
    opacity: 0.5;
}

.reportDnD ul[dnd-list] .dndPlaceholder {
    background-color: #ddd;
    display: block;
    height: 175px;
    width: 225px;
    float:left;
}

.reportDnD ul[dnd-list] li {
    display: block;
    margin-bottom: -1px;
}

/*End of Reports Drag and Drop*/

/* PLACEHOLDER TEXT COLOR */
::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	color: #777 !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: #777 !important;
	opacity: 1 !important;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: #777 !important;
	opacity: 1 !important;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #777 !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
	color: #777 !important;
}
::placeholder { /* Most modern browsers support this now. */
	color: #777 !important;
}

/************Dashboard**************/
div.dashboardSM{
    zoom: 80%;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
}
div.dashboardMD{
    zoom: 85%;
    -moz-transform: scale(0.85);
    -moz-transform-origin: 0 0;
}

div#challengeCard, div#promotionCard, div#topPharm, div#tabQuantity, div#tabValue, div#tabTotal {
	text-align: center;
}

table.dashboardTable{
	counter-reset: rowNumber;
}

table.dashboardTable tr > td:first-child{
	counter-increment: rowNumber;
}

table.dashboardTable tr td:first-child::before{
	content: counter(rowNumber);
    min-width: 1em;
    margin-right: 0.5em;
}

.table > thead > tr.primary > th,
.table > tbody > tr.primary > td {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}

@keyframes blink {
    0% {
        border-color: rgb(0, 113, 255)
    }
    50% {
        border-color: rgb(41, 0, 119)
    }
    100% {
        border-color: rgb(0, 113, 255)
    }
}

@-webkit-keyframes blink {
    0% {
        border-color: rgb(0, 113, 255)
    }
    50% {
        border-color: rgb(41, 0, 119)
    }
    100% {
        border-color: rgb(0, 113, 255)
    }
}

.download {
    padding: 15px 15px 15px 15px;
    text-align: center;
    margin-bottom: 4px;
    font-size: 24px;
    border-radius: 5px;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -moz-animation: blink normal 1.5s infinite ease-in-out;
    /* Firefox */
    -webkit-animation: blink normal 1.5s infinite ease-in-out;
    /* Webkit */
    -ms-animation: blink normal 1.5s infinite ease-in-out;
    /* IE */
    animation: blink normal 1.5s infinite ease-in-out;
    /* Opera */
}

@charset "utf-8";
body .website {
	color: #A7A8AA;
	overflow:hidden;
}

.website h1 {
	margin: 30px 0;
	text-align: center;
	text-transform: uppercase;
}
.website h4 {
	text-shadow: 2px 2px 1px #A7A8AA;
}
.website #main {
	padding-bottom: 51px;
}

/* HEADER STYLES --------------------------------------------------------------------------------- */
.website #header_wrapper {
	background: #ffffff;
	border-bottom: 4px solid #FF7F31;
	padding: 0;
	width: 100% !important;
}

/* LOG IN FROM ----------------------------------------------------------------------------------- */
.website #login-dp {
    min-width: 350px;
    padding: 14px;
    overflow:hidden;
}
.website #login-dp h4 {
	text-shadow: 2px 2px 1px #FFFFFF;
}
.website #login-dp .form-control:focus {
	border-color: #C0DF16;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(194, 219, 13, .6);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(194, 219, 13, .6);
	-ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(194, 219, 13, .6);
	-o-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(194, 219, 13, .6);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(194, 219, 13, .6);
}
.website #login-dp .form-group {
   	margin-bottom: 10px;
}
/* NAV BAR STYLES -------------------------------------------------------------------------------- */
.website .navbar {
	border: 0px solid #fff;
	margin-bottom: 0px;
}
.website .navbar-menu ul li a {
	color: #A7A8AA;
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
}

.website .navbar-menu li.active a, .website .navbar-menu li.active a:hover,
.website .navbar-menu li a:focus, .website .navbar-menu li.active a:focus {
	background-color: transparent;
	color: #C0DF16;
}
.website .navbar-menu li a:hover {
    background-color: transparent;
    color: #FF7F31;
}
@media ( max-width : 768px) {
	.website h4 {
		font-size: 13px;
	}

	.website .navbar-menu ul li a {
		padding: 4px 10px;
	}
}

/* FIRST SECTION --------------------------------------------------------------------------------- */
.website #first_section {
	background: #C0DF16;
	color: #fff;
	padding-bottom: 50px;
	position: relative;
	transition: background 5s;
	-moz-transition: background 5s;
	-ms-transition: background 5s;
	-o-transition: background 5s;
	-webkit-transition: background 5s;
}

.website #first_section.background2 {
	background: #A7A8AA;
}

.website .main-circle {
	text-align: center;
}

.website .main-circle a {
	background: url("../images/site_partners_sprite.png") no-repeat 0 0;
	display: inline-block;
	height: 160px;
	width: 160px;
}

.website .main-circle a.opt1 {background-position: 0px -160px;}
.website .main-circle a.opt1.active, .website .main-circle a.opt1:hover {	background-position: 0px 0px;}
.website .main-circle a.opt2 {background-position: -160px -160px;}
.website .main-circle a.opt2.active, .website .main-circle a.opt2:hover {background-position: -160px 0px;}
.website .main-circle a.opt3 {background-position: -320px -160px;}
.website .main-circle a.opt3.active, .website .main-circle a.opt3:hover {background-position: -320px -0px;}
.website .main-circle a.opt4 {background-position: -480px -160px;}
.website .main-circle a.opt4.active, .website .main-circle a.opt4:hover {background-position: -480px -0px;}

.website .main-labels.animated {
	opacity: 1;
	animation: popin 400ms forwards 0ms ease-in-out;
	-moz-animation: popin 400ms forwards 0ms ease-in-out;
	-ms-transform: popin 400ms forwards 0ms ease-in-out;
	-o-animation: popin 400ms forwards 0ms ease-in-out;
	-webkit-animation: popin 400ms forwards 0ms ease-in-out;
}

/* ABOUT US SECTION ------------------------------------------------------------------------------ */
.website #aboutUs {
	padding-bottom: 50px;
}
.website .aboutus_section {
	padding: 20px 0 60px;
}

.website .aboutus_section a {
	color: #FF7F31;
}

.website .aboutus_bottom {
	padding: 20px 0 0 0px;
}

.website .aboutus_bottom span {
	display: block;
	font-size: 18px;
	margin: 0 0 20px 0;
}

.website a.contact_btn {
	background: #fff;
	border: 2px solid #C0DF16;
	border-radius: 4px;
	color: #C0DF16;
	display: block;
	height: 40px;
	font-size: 16px;
	font-weight: 600;
	line-height: 40px;
	text-align: center;
	text-transform: uppercase;
	width: 170px;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
}

.website a.contact_btn:hover {
	background: #C0DF16;
	color: #fff;
	text-decoration:none;
}

/* INFINITY CAROUSEL ----------------------------------------------------------------------------- */
.website .infinity-carousel .img-wrap {
	border-radius: 50%;
	height: 170px;
	margin: 0 auto 24px;
	overflow: hidden;
	width: 220px;
    display: flex;
}

.website .infinity-carousel img {
	height: 100%;
    margin: 0 auto;
}

.website .infinity-carousel .text {
	display: block;
	height: 74px;
	margin: 0 auto;
	position: relative;
	text-align: center;
	transition: opacity 0.4s linear;
	width: 180px;
}

.website .infinity-carousel .nav {
	background-color: transparent;
	border: 0;
	color: #A7A8AA;
	cursor: pointer;
	opacity: 0.5;
	padding: 0 5px;
	position: absolute;
	top: 110px;
	z-index: 50;
	-webkit-apperance: none;
}

.website .infinity-carousel .nav:hover {
	opacity: 1;
}

.website .infinity-carousel .nav.next {
	right: 0;
}

.website .infinity-carousel .track  {
	width: 10000px !important;
}
.website .infinity-carousel .slide  {
	outline: 1px solid #ddd;
	width: 380px !important;
}
.website .infinity-carousel .carousel-item  {
	width: 380px !important;
}

/* PARTNERS -------------------------------------------------------------------------------------- */
.website #partners {
	color: #fff;
	background-color: #A7A8AA;
	min-height: 435px;
	padding-bottom: 50px;
}

.website .partner-circle {
	text-align: center;
}

.website .partner-circle span {
	background: url("../images/site_partners_sprite.png") no-repeat 0 0;
	display: inline-block;
	height: 160px;
	width: 160px;
}

.website .partner-circle span.opt1 {background-position: 0px -160px;}
.website .partner-circle span.opt1.active, .website .partner-circle span.opt1:hover {background-position: 0px 0px;}
.website .partner-circle span.opt2 {background-position: -160px -160px;}
.website .partner-circle span.opt2.active, .website .partner-circle span.opt2:hover {background-position: -160px 0px;}
.website .partner-circle span.opt3 {background-position: -320px -160px;}
.website .partner-circle span.opt3.active, .website .partner-circle span.opt3:hover {background-position: -320px -0px;}
.website .partner-circle span.opt4 {background-position: -480px -160px;}
.website .partner-circle span.opt4.active, .website .partner-circle span.opt4:hover {background-position: -480px -0px;}

.website .partner-labels {
	text-align: left;
}

.website .partner-labels h4 {
	text-align: center;
}

.website .partner-labels.animated {
	opacity: 1;
	max-height: 250px;
	animation: popin 400ms forwards 0ms ease-in-out;
	-o-animation: popin 400ms forwards 0ms ease-in-out;
	-moz-animation: popin 400ms forwards 0ms ease-in-out;
	-ms-transform: popin 400ms forwards 0ms ease-in-out;
	-webkit-animation: popin 400ms forwards 0ms ease-in-out;
}

/* CONTACT --------------------------------------------------------------------------------------- */
.website #contact {
	background: #232323;
	padding-bottom: 50px;
}

.website #contact h4 {
	text-shadow: none;
}

.website .contact_info .detail p {
	color: #ffffff;
}

.website .contact_info .detail a {
	color: #ffffff;
}
.website .contact_info .detail a:hover {
	color: #C0DF16;
}

.website .input-text {
	background: transparent;
	color: #fff;
	height: 40px;
	transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}

.website .input-text:focus {
	border: 1px solid #C0DF16;
	color: #fff;
	outline: 0;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
	-ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
	-o-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(124, 197, 118, 0.3);
}

.website .input-text.text-area {
	height: 150px;
	resize: none;
	overflow: auto;
}

.website .input-btn {
	background: transparent;
	border: 2px solid #A7A8AA;
	border-radius: 4px;
	color: #A7A8AA;
	display: block;
	height: 40px;
	font-weight: 600;
	text-transform: uppercase;
	width: 170px;
	transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
}
.website .input-btn:hover {
	color: #C0DF16;
	border: 2px solid #C0DF16;
	text-decoration:none;
}

@media ( max-width : 768px) {
	.website .navbar .navbar-collapse, .website .navbar .navbar-form {
		border-color: #A7A8AA;
	}
	.website .navbar .navbar-toggle .icon-bar {
		background: #C0DF16;
	}
	.website .navbar .navbar-toggle {
		border-color: transparent;
	}
	.website .navbar .navbar-toggle:hover, .website .navbar .navbar-toggle:focus {
		background: none;
		outline: none;
	}
	.website .navStyle ul li {
		display: block;
	}
	.website .navStyle {
		float: right;
		width: 100%;
		text-align: center;
	}
	.website .navStyle ul li:last-child {
		margin: 0px;
	}
	.website .navbar .navbar-nav>li>a {
		color: #A7A8AA;
		background: none;
	}
	.website .navbar .navbar-nav>li>a:hover, .website .navbar .navbar-nav>li>a:focus {
		color: #FFF;
		background-color: #C0DF16;
	}
}
